import { type MappedReasons, apiAuth } from "@app/services";
import { parseError } from "@app/utils";

import type { AppThunk } from "../store";
import {
	setNewPageLoad,
	setResendConfirmationEmailError,
	setResendConfirmationEmailLoading,
	setResetPasswordError,
	setResetPasswordLoading,
	setUnverifiedEmail,
} from "./auth-slice";
import { OTPMethod } from "@app/features/otp/use-otp";

export const resendConfirmationEmailAction =
	(email: string, callback?: (errors?: string[]) => void): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;

		try {
			dispatch(setResendConfirmationEmailLoading(true));
			dispatch(setResendConfirmationEmailError(null));

			const response = await apiAuth.resendConfirmationEmail(email);

			if (typeof response === "object" && response?.reasons) {
				dispatch(setResendConfirmationEmailError(response.reasons));
				reasons = response.reasons;
			}
		} catch (error: any) {
			reasons = [parseError(error)];

			dispatch(setResendConfirmationEmailError(error));
		} finally {
			if (callback) callback(reasons);
			dispatch(setResendConfirmationEmailLoading(false));
		}
	};

export const resetPasswordAction =
	(
		uid: string,
		token: string,
		password: string,
		otpToken?: string,
		otpMethod?: OTPMethod,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setResetPasswordLoading(true));
			dispatch(setResetPasswordError(null));

			const response = await apiAuth.resetPassword(
				uid,
				token,
				password,
				otpToken,
				otpMethod,
			);

			if (typeof response === "object" && response?.reasons) {
				dispatch(setResetPasswordError(response.reasons));
				reasons = response.reasons;
				if (response.mappedReasons) {
					mappedReasons = response.mappedReasons;
				}
			}
		} catch (error: any) {
			reasons = [parseError(error)];
			dispatch(setResetPasswordError(error));
		} finally {
			if (callback) callback(reasons, mappedReasons);
			dispatch(setResetPasswordLoading(false));
		}
	};

export const storeUnverifiedEmailAction =
	(unverifiedEmail: string | null): AppThunk =>
	async (dispatch) => {
		dispatch(setUnverifiedEmail(unverifiedEmail));
	};

export const setNewPageLoadAction =
	(newPageLoad: boolean): AppThunk =>
	async (dispatch) => {
		dispatch(setNewPageLoad(newPageLoad));
	};
