import { useDispatch } from "react-redux";

import type { AppDispatch } from "@app/redux";
import {
	resendConfirmationEmailAction,
	resetPasswordAction,
	setNewPageLoadAction,
	storeUnverifiedEmailAction,
} from "@app/redux/auth/auth-actions";
import { type MappedReasons } from "@app/services";
import { OTPMethod } from "@app/features/otp/use-otp";

export const useResendConfirmationEmail = () => {
	const dispatch: AppDispatch = useDispatch();

	const resendConfirmationEmail = (email: string, callback?: () => void) => {
		dispatch(resendConfirmationEmailAction(email, callback));
	};

	return [resendConfirmationEmail];
};

export const useResetPassword = () => {
	const dispatch: AppDispatch = useDispatch();

	const resetPassword = (
		uid: string,
		token: string,
		password: string,
		otpToken?: string,
		otpMethod?: OTPMethod,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(
			resetPasswordAction(uid, token, password, otpToken, otpMethod, callback),
		);
	};
	return [resetPassword];
};

export const useStoreUnverifiedEmail = () => {
	const dispatch: AppDispatch = useDispatch();

	const storeUnverifiedEmail = (email: string | null) => {
		dispatch(storeUnverifiedEmailAction(email));
	};

	return [storeUnverifiedEmail];
};

export const useSetNewPageLoad = () => {
	const dispatch: AppDispatch = useDispatch();

	const setNewPageLoad = (newPageLoad: boolean) => {
		return dispatch(setNewPageLoadAction(newPageLoad));
	};

	return [setNewPageLoad];
};
