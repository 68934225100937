import { Dialog } from "@app/components/dialog";

import { Button } from "@app/components/button";
import { ContactNumberField } from "@app/components/contact-number-field";
import { ButtonGroup } from "@app/features/auth/button-group";
import { useMediaQuery } from "@app/hooks/use-media-query";
import PhoneIcon from "./phone.svg?react";
import WhatsAppIcon from "@app/icons/whatsapp.svg?react";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { OTPMethod, useOTP } from "../../use-otp";
import { ApiErrors } from "@app/components/api-errors";

import styles from "./index.module.css";
import { isValidNumber } from "libphonenumber-js";

type Form = {
	contact_number: string;
};

export const DeviceModal = ({
	onClose,
	onConfirm,
}: {
	onConfirm: (contactNumber: string, method: OTPMethod) => void;
	onClose: () => void;
}) => {
	const [apiErrors, setApiErrors] = useState<Array<string>>([]);
	const { createOTPDevice } = useOTP();
	const isMobile = useMediaQuery();
	const [submittedContactNumber, setSubmittedContactNumber] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [step, setStep] = useState<"number" | "method">("number");

	const methods = useForm<Form>({
		shouldFocusError: false,
		defaultValues: {
			contact_number: "",
		},
	});
	const {
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
	} = methods;

	const onSubmit: SubmitHandler<Form> = async (data) => {
		if (!data.contact_number || data.contact_number.length < 4) {
			setError("contact_number", {
				message: "This field is required",
			});
			return;
		}
		if (!isValidNumber(data.contact_number)) {
			setError("contact_number", {
				message: "Please provide a valid phone number",
			});
			return;
		}
		setSubmittedContactNumber(data.contact_number);
		setStep("method");
	};

	const handleCreateDevice = async (type: OTPMethod) => {
		setIsSubmitting(true);
		const errors = await createOTPDevice({
			contact_number: submittedContactNumber,
			message_channel: type,
		});
		if (errors) {
			if (errors.fieldErrors.length > 0) {
				const contactError = errors.fieldErrors.find(
					(error) => error.name === "contact_number",
				);
				if (contactError) {
					setError("contact_number", {
						message: contactError.message,
					});
					setStep("number");
				}
			}
			setApiErrors(errors.apiErrors);
		} else {
			onConfirm(submittedContactNumber, type);
		}
		setIsSubmitting(false);
	};

	return (
		<Dialog
			isOpen
			size="md"
			onBack={
				isMobile
					? step === "method"
						? () => setStep("number")
						: onClose
					: undefined
			}
			fullscreen={isMobile}
			showTopbar={isMobile}
			title={
				step === "number" ? "Enter mobile number" : "Select delivery method"
			}
			description={
				step === "number"
					? "OTPs will be sent to this mobile number."
					: "Choose how you would like to receive your OTP’s."
			}
			actions={
				step === "number" ? (
					<>
						<Button variant="secondary" onClick={onClose}>
							Back
						</Button>
						<Button form="device-form" type="submit">
							Continue
						</Button>
					</>
				) : (
					<Button
						disabled={isSubmitting}
						minWidth={110}
						centered
						onClick={() => setStep("number")}
					>
						Back
					</Button>
				)
			}
		>
			{step === "number" ? (
				<FormProvider {...methods}>
					<form id="device-form" onSubmit={handleSubmit(onSubmit)}>
						<ContactNumberField
							label="Mobile number"
							placeholder={
								isMobile ? "Enter mobile number" : "Enter your mobile number"
							}
							onBlur={() => {
								clearErrors("contact_number");
							}}
							error={errors.contact_number}
						/>
					</form>
				</FormProvider>
			) : (
				<>
					<ButtonGroup
						disabled={isSubmitting}
						data={[
							{
								icon: <PhoneIcon width={40} height={40} color="#56A7A2" />,
								title: "SMS",
								onClick: () => handleCreateDevice("sms"),
							},
							{
								icon: <WhatsAppIcon width={40} height={40} color="#56A7A2" />,
								title: "Whatsapp",
								onClick: () => handleCreateDevice("whatsapp"),
							},
						]}
					/>
					<ApiErrors className={styles.errors} errors={apiErrors} />
				</>
			)}
		</Dialog>
	);
};
