import { arbitrageApiUrl } from "@app/config/env";
import { paths } from "@app/constants/paths";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import axios from "axios";

type ResetPasswordResponse = {
	uid: string;
	token: string;
	password: string;
	otp_token: string;
	otp_method: string;
};

export const useAuth = () => {
	const handleForgotPassword = async (email: string) => {
		await api.post(`users/send-password-reset-link/${email}/`);
	};

	const handleResetPassword = async (
		uid: string,
		token: string,
		password: string,
	) => {
		try {
			const { data } = await api.post<ResetPasswordResponse>(
				"users/reset-password/",
				{
					uid,
					token,
					password,
				},
			);
			return [data, null];
		} catch (error) {
			return [null, getFormErrors(error)];
		}
	};

	return {
		onForgotPassword: handleForgotPassword,
		onArbitrageForgotPassword: async (email: string) => {
			await axios.post(`${arbitrageApiUrl}api/auth/users/reset_password/`, {
				email: email.trim(),
			});
		},
		onArbitrageResendActivate: async (email: string) => {
			try {
				await axios.post(
					`${arbitrageApiUrl}api/auth/users/resend_activation/`,
					{
						email: email.trim(),
					},
				);
			} catch (error) {
				return getFormErrors(error);
			}
		},
		onLogout: async (params = "") => {
			await api.post("auth/logout/");
			window.sessionStorage.clear();
			window.location.href = paths().login + params;
		},
		onResetPassword: handleResetPassword,
	};
};
