import { useState } from "react";
import { DeviceModal } from "../modals/device-modal";
import { OTPInputModal } from "../modals/otp-input-modal";
import { ResendOTPModal } from "../modals/resend-otp-modal";
import { TrustDeviceFlow } from "../trust-device-flow";

export const InitOTPDeviceFlow = ({
	onComplete,
	onClose,
}: {
	onComplete: () => void;
	onClose: () => void;
}) => {
	const [contactNumber, setContactNumber] = useState("");
	const [showDeviceModal, setShowDeviceModal] = useState(true);
	const [showOTPInputModal, setShowOTPInputModal] = useState(false);
	const [showResendOTPModal, setShowResendOTPModal] = useState(false);
	const [showTrustDeviceFlow, setShowTrustDeviceFlow] = useState(false);

	return (
		<>
			{showDeviceModal && (
				<DeviceModal
					onClose={onClose}
					onConfirm={(contactNumber) => {
						setContactNumber(contactNumber);
						setShowDeviceModal(false);
						setShowOTPInputModal(true);
					}}
				/>
			)}
			{showOTPInputModal && (
				<OTPInputModal
					contactNumber={contactNumber}
					onResend={() => {
						setShowResendOTPModal(true);
						setShowOTPInputModal(false);
					}}
					variant="device"
					onClose={() => {
						setShowOTPInputModal(false);
						setShowDeviceModal(true);
					}}
					onConfirm={() => {
						setShowOTPInputModal(false);
						setShowTrustDeviceFlow(true);
					}}
				/>
			)}
			{showResendOTPModal && (
				<ResendOTPModal
					contactNumber={contactNumber}
					variant="device"
					onConfirm={() => {
						setShowResendOTPModal(false);
						setShowOTPInputModal(true);
					}}
					onClose={() => {
						setShowResendOTPModal(false);
						setShowOTPInputModal(true);
					}}
				/>
			)}
			{showTrustDeviceFlow && (
				<TrustDeviceFlow onComplete={onComplete} onClose={onComplete} />
			)}
		</>
	);
};
